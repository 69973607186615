<template>
    <div class="about-me">
        <div class="c-title">
            <h1>关于我</h1>
            <p>前端web攻城狮</p>
        </div>
        <!-- 内容输出部分 -->
        <div class="c-wrap">
            <div class="person-con">
                <div class="seat"></div>
                <h1>自述</h1>
                <div class="row">
                    <div class="col">所在地：成都</div>
                    <div class="col">目标：做最酷的人。</div>
                </div>
                <div class="row">
                    <div class="col">目前岗位：WEB前端开发</div>
                    <div class="col">联系方式：2642376607@qq.com</div>
                </div>
                <h1>相关经历</h1>
                <ul>
                    <li>2015-2019 成都信息工程大学 本科 计算机科学与技术专业</li>
                    <li>2020.6-2021.10 前端开发 重庆市瑞德铭科技发展有限公司成都分公司</li>
                    <li>2021.11-至今 前端开发 灵悦互动科技有限公司</li>
                </ul>
                <h1>相关作品</h1>
                <ul>
                    <li><a href="http://www.a-puhaha.com/about-me/" target="_blank">查看详情</a></li>
                </ul>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'about-me',

        data() {
            return {}
        },

        mounted() {},

        methods: {},
    }
</script>

<style lang="less" scoped>
    .about-me {
        position: relative;
        min-height: calc(100vh - 50px);
        background-color: #f1f3f4;
        .c-title {
            box-sizing: border-box;
            height: 100px;
            padding: 20px 20px 0;
            background-color: #f9f9f9;
            h1 {
                font-weight: 200;
            }
            p {
                margin-top: 5px;
                letter-spacing: 2px;
                color: #777;
                font-size: 13px;
            }
        }
        .c-wrap {
            padding: 20px;
            .person-con {
                min-height: 80vh;
                border-radius: 4px;
                padding: 0 32px 32px;
                margin-bottom: 50px;
                background-color: #fff;
            }
            .seat {
                height: 1px;
                width: 1px;
            }
            //
            h1 {
                margin: 20px 0;
                width: fit-content;
                color: #666;
                line-height: 1.4;
                font-weight: 700;
                background: linear-gradient(to bottom, transparent 60%, rgba(189, 202, 219, 0.3) 0) no-repeat;
            }
            .row {
                display: flex;
                width: 100%;
                margin-bottom: 10px;

                .col {
                    width: 50%;
                    color: #666;
                }
            }
            ul {
                color: #666;
                padding-inline-start: 15px;
                li {
                    margin-bottom: 10px;
                    list-style: disc;
                }
            }
        }
    }
</style>
